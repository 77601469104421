import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { getIndustriesSetting } from '../../../redux/slices/organisations';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProfileSetting } from '../../../redux/slices/user';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIndustriesSetting());
    dispatch(getProfileSetting());
  }, [dispatch]);

  const sectionsEnabled = useSelector((state) => state.organisation?.industriesSetting?.sectionsEnabled);

  const banking = useSelector((state) => state.user?.hideContactNo?.Banking);

  const [nav, setNav] = useState(navConfig);

  useEffect(() => {
    if (sectionsEnabled) {
      let dd = sectionsEnabled.split(',');
      if (banking) {
        dd = [...dd, 'banking'];
      } else {
        dd = [...dd];
      }

      const c = ['transport', 'Courier / Cargo', 'Courier', 'Ecommerce or online sales', 'banking'];

      if (dd) {
        const result = navConfig[0].items.filter((item) => {
          if (c.includes(item.title) && !dd.includes(item.title)) {
            return false;
          }
          return true;
        });

        setNav([{ subheader: 'general', items: result }]);
      }
    } else if ((sectionsEnabled === null && banking) || (sectionsEnabled === '' && banking)) {
      const dd = ['banking'];
      const c = ['transport', 'Courier / Cargo', 'Courier', 'Ecommerce or online sales', 'banking'];
      if (dd) {
        const result = navConfig[0].items.filter((item) => {
          if (c.includes(item.title) && !dd.includes(item.title)) {
            return false;
          }
          return true;
        });
        setNav([{ subheader: 'general', items: result }]);
      }
    } else {
      const result = navConfig[0].items.filter(
        (e) =>
          e.title !== 'transport' &&
          e.title !== 'Courier / Cargo' &&
          e.title !== 'Courier' &&
          e.title !== 'Ecommerce or online sales' &&
          e.title !== 'banking'
      );
      setNav([{ subheader: 'general', items: result }]);
    }
  }, [sectionsEnabled, banking]);

  return (
    <Box {...other}>
      {nav.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => (
            <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
          ))}
        </List>
      ))}
    </Box>
  );
}
